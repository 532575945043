import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

class setupEmployees extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:6,assignment:'',ProgName:'',ProjName:'',Resp:'',SName:'', manager:'',salaryrate:'', eyear:'', emonth:'', 
			eday:'', salarytype:'',defaultpay:'',UpdatingProgram:[], employeeUpdate:'', FindManager:[],
			UpdatingEmployee:[], selectedUpdate:'',AssignmentUpdate:[],
			roleid:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleProgUpdateChange=this.handleProgUpdateChange.bind(this)
		this.handleEmployeeChange=this.handleEmployeeChange.bind(this)
		this.handleAssignment=this.handleAssignment.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}				
		axios.post(process.env.REACT_APP_API_ENV+'api/programupdate.php', para)
			.then(result => {						
				this.setState({UpdatingProgram:result.data})								
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/findindustry.php', para)
			.then(result => {							
				this.setState({Industry:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/findmanager.php', para)
			.then(result => {							
				this.setState({FindManager:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/employeeupdate.php', para)
			.then(result => {						
				this.setState({UpdatingEmployee:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/assignmentupdate.php', para)
			.then(result => {										
				this.setState({AssignmentUpdate:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php', para)
			.then(result => {
				this.setState({roleid:result.data[5]})																	
		})
	}	
	handleAssignment = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({employeeUpdate:""})
		this.setState({selectedUpdate:""})
		this.setState({manager:""})
		this.setState({salaryrate:""})
		this.setState({defaultpay:""})
		this.setState({Resp:""});
	}
	handleProgUpdateChange = event => {
		this.setState({selectedUpdate: event.target.value})	
		this.setState({Resp:""});		
		this.setState({ProgName:this.state.UpdatingProgram.filter(item=>item[0] == event.target.value).map(item=>item[3])})
		this.setState({ProjName:this.state.UpdatingProgram.filter(item=>item[0] == event.target.value).map(item=>item[4])})	
	}
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
		
	handleEmployeeChange = event => {		
		this.setState({selectedUpdate:""})
		this.setState({employeeUpdate: event.target.value})	
		if(this.state.assignment == 1) {				
			this.setState({salaryrate:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[9]).[0]})			
			this.setState({salarytype:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[10]).[0]})			
		}
		if(this.state.assignment == 2) {				
			this.setState({manager:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[8]).[0]})
			this.setState({salaryrate:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[13]).[0]})			
			this.setState({salarytype:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[15]).[0]})			
			this.setState({defaultpay:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[12]).[0]})			
		}		
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {assignment,employeeUpdate,SName,manager,salaryrate,eyear, emonth,eday,salarytype,ProgName,ProjName,defaultpay} = this.state		
		if (this.state.assignment == "") {
				alert('Please select manage assignment type, Add, Update or Delete?')
				return false
		}
		if(this.state.assignment == "1")  {
			if(!employeeUpdate) {
				alert('Please select employee for the assignment')
				return false
			}
			if(this.state.selectedUpdate == "") {
				alert('Please select the being assigned program')
				return false
			}			
			if(!manager) {
				alert('Please assign this program payroll approval manager')
				return false
			}		
			if(this.state.roleid == "1") {
				if(!salaryrate) {
					alert('Please set up the salary rate for this assignment')
					return false
				}
				if(!salarytype) {
					alert('Please select salary type for this assignment')
					return false
				}
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				assignment:this.state.assignment,
				employeeUpdate:this.state.employeeUpdate,
				SName:this.state.selectedUpdate,				
				manager: this.state.manager,
				salaryrate: this.state.salaryrate,
				salarytype: this.state.salarytype,
				roleid:this.state.roleid
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});				
		} else if(this.state.assignment == "2")  {
			if(!employeeUpdate) {
				alert('Please select employee assignment for the update')
				return false
			}				
			if(!manager) {
				alert('Please assign this program payroll approval manager')
				return false
			}	
			if(this.state.roleid == "1") {
				if(!salaryrate) {
					alert('Please set up the salary rate for this employee')
					return false
				}
				if(!eyear) {
					alert('Please select Effective year')
					return false
				}
				if(!emonth) {
					alert('Please select Effective month')
					return false
				}
				if(!eday) {
					alert('Please select Effective day')
					return false
				}
				if(!salarytype) {
					alert('Please select salary type for this assignment')
					return false
				}
			}
			if(!defaultpay) {
				alert('Please determine the default payment, normally it is No')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				assignment:this.state.assignment,
				employeeUpdate:this.state.employeeUpdate,								
				manager: this.state.manager,
				salaryrate: this.state.salaryrate,
				salarytype: this.state.salarytype,
				defaultpay: this.state.defaultpay,
				roleid:this.state.roleid,
				eyear:this.state.eyear,
				emonth:this.state.emonth,
				eday:this.state.eday
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.assignment == "3")  {
			if(!employeeUpdate) {
				alert('Please select the employee and assignment to delete')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				assignment:this.state.assignment,
				employeeUpdate:this.state.employeeUpdate				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {				
		const indname = (this.state.Industry == "O") ? "Campus": "Program"	
		const projname = (this.state.Industry == "O") ? "Program": "Project"	
		return (		
			<div>      		
				<CommonHeader titles="Assignment"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage assignment</h2></label>							
						<select name="assignment" id="assignment" style={{width:'150px'}} value={this.state.assignment} onChange={this.handleAssignment}>
							<option value="">--Manage assignment--</option>
							<option value="1">Add Employee New Assignment</option>															
							<option value="2">Update Employee Assignment</option>												
							<option value="3">Remove Employee Assignment</option>		
						</select><br/><br/>
					</div>
					{(()=>{
					{/*assign employee new project*/}
						if(this.state.assignment == "1") {return(<div>
							<label><h2>Select Employee for Assignment</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Employee--</option>									
									{this.state.UpdatingEmployee.map((sub,index) => (<option value={sub[0]} key={index}>{sub[7]} {sub[8]}</option>))}			
								</select>
							<br/><br/>							
							<div className="forinput">
								<label><h2>Assign {this.state.UpdatingEmployee.filter(item=>item[0] == this.state.employeeUpdate).map(item=>item[7])} to:</h2></label>
								<select id="SName" name="SName" value = {this.state.selectedUpdate} style={{width:'160px'}} onChange={this.handleProgUpdateChange}>
									<option value="">--Select {indname} and {projname}--</option>									
									{this.state.UpdatingProgram.map((id,index) => (<option value={id[0]} key={index}>{id[3]}- {id[4]}</option>))}			
								</select>
							</div><br/>							
							<div className="forinput">
								<label><h2>{indname}:</h2></label>								
								<input type="text" id="ProgName" name="ProgName" value = {this.state.UpdatingProgram.filter(item=>item[0] == this.state.selectedUpdate).map(item=>item[3])} style={{width:'160px'}} readOnly />									
							</div><br/>
							<div className="forinput">
								<label><h2>{projname}</h2></label>
								<input type="text" name="ProjName" id="ProjName" value = {this.state.UpdatingProgram.filter(item=>item[0] == this.state.selectedUpdate).map(item=>item[4])} style={{width:'160px'}} readOnly />								
							</div><br/>	
							<div className="forinput">
								<label><h2>Assignment Approval Manager:</h2></label>								
								<select id="manager" name="manager" value = {this.state.manager} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.FindManager.map(item=>(<option value={item[0]}>{item[3]} {item[4]}</option>))}
								</select>
							</div><br/>	
							{(()=>{if(this.state.roleid == "1") {return(<div>
								<div className="forinput">
									<label><h2>Salary Rate</h2></label>
									<input type="text" name="salaryrate" id="salaryrate" value = {this.state.salaryrate} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><br/>	
								<div className="forinput">
									<label><h2>Salary Type</h2></label>
									<select type="text" name="salarytype" id="salarytype" value = {this.state.salarytype} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="Hourly">--Hourly--</option>
										<option value="Yearly">--Yearly--</option>
									</select>
								</div><br/>	
							</div>)}})()}	
							<h1>{(this.state.roleid == "0" && this.state.manager >0 ) ? "Please check with manager to make sure the employee pay rate for this assignment or ask manager to update the rate":""}</h1>
							<h1>{this.state.Resp}</h1><br/><br/>												
						{/*update assignment*/}
						</div>)} else if(this.state.assignment == "2") {return(<div>
							<label><h2>Select Employee for Assignment Update</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Employee--</option>									
									{this.state.AssignmentUpdate.map((sub,index) => (<option value={sub[14]} key={index}>{sub[6]+" "+sub[7]+"-"+sub[3] + ": " + sub[4]}</option>))}			
								</select>
							<br/><br/>														
							<div className="forinput">
								<label><h2>{indname}:</h2></label>								
								<input type="text" id="ProgName" name="ProgName" value = {this.state.AssignmentUpdate.filter(item=>item[14] == this.state.employeeUpdate).map(item=>item[3])} style={{width:'160px'}} readOnly />									
							</div><br/>
							<div className="forinput">
								<label><h2>{projname}</h2></label>
								<input type="text" name="ProjName" id="ProjName" value = {this.state.AssignmentUpdate.filter(item=>item[14] == this.state.employeeUpdate).map(item=>item[4])} style={{width:'160px'}} readOnly />								
							</div><br/>	
							<div className="forinput">
								<label><h2>Assignment Approval Manager:</h2></label>								
								<select id="manager" name="manager" value = {this.state.manager} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.FindManager.map((item,index)=>(<option value={item[0]} key={index}>{item[3]} {item[4]}</option>))}
								</select>
							</div><br/>	
							{(()=>{if(this.state.roleid == "1") {return(<div>
								<div className="forinput">
									<label><h2>Salary Rate</h2></label>
									<input type="text" name="salaryrate" id="salaryrate" value = {this.state.salaryrate} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><br/>								
								<div className="forinput">
									<label><h2>Effective Date</h2></label>
									<select name="eyear" id="eyear" value = {this.state.eyear} style={{width:'60px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value={thisyear}>{thisyear}</option>
										<option value={nextyear}>{nextyear}</option>	
									</select>
									<select name="emonth" id="emonth" value = {this.state.emonth} style={{width:'50px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="1">January</option>
										<option value="2">February</option>
										<option value="3">March</option>
										<option value="4">April</option>
										<option value="5">May</option>
										<option value="6">June</option>
										<option value="7">July</option>
										<option value="8">August</option>
										<option value="9">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>									
									<select type="text" name="eday" id="eday" value = {this.state.eday} style={{width:'50px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
										<option value="13">13</option>
										<option value="14">14</option>
										<option value="15">15</option>
										<option value="16">16</option>
										<option value="17">17</option>
										<option value="18">18</option>
										<option value="19">19</option>
										<option value="20">20</option>
										<option value="21">21</option>
										<option value="22">22</option>
										<option value="23">23</option>
										<option value="24">24</option>
										<option value="25">25</option>
										<option value="26">26</option>
										<option value="27">27</option>
										<option value="28">28</option>
										<option value="29">29</option>
										<option value="30">30</option>
										<option value="31">31</option>
									</select>
								</div><br/>									
								<div className="forinput">
									<label><h2>Salary Type</h2></label>
									<select type="text" name="salarytype" id="salarytype" value = {this.state.salarytype} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="Hourly">--Hourly--</option>
										<option value="Yearly">--Yearly--</option>
									</select>
								</div><br/>
							</div>)}})()}	
							<div className="forinput">
								<label><h2>Default {indname} and {projname} </h2></label>								
								<select id="defaultpay" name="defaultpay" value = {this.state.defaultpay} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									<option value="1">Yes</option>
									<option value="0">No</option>								
								</select>
							</div><br/>
								<h1>{this.state.Resp}</h1><br/><br/>												
						{/*delete assignment*/}
						</div>)} else if(this.state.assignment == "3") {return(<div>
							<label><h2>Delete Employee Assignment</h2></label>																						
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Employee--</option>									
									{this.state.AssignmentUpdate.map((sub,index) => (<option value={sub[9]} key={index}>{sub[6]} {sub[7]}: {sub[3]}-{sub[4]}</option>))}			
								</select>
							<br/><br/>	
							<div className="forinput">
								<label><h2>{indname}:</h2></label>								
								<input type="text" id="ProgName" name="ProgName" value = {this.state.AssignmentUpdate.filter(item=>item[9] == this.state.employeeUpdate).map(item=>item[3])} style={{width:'160px'}} readOnly />									
							</div><br/>
							<div className="forinput">
								<label><h2>{projname}</h2></label>
								<input type="text" name="ProjName" id="ProjName" value = {this.state.AssignmentUpdate.filter(item=>item[9] == this.state.employeeUpdate).map(item=>item[4])} style={{width:'160px'}} readOnly />								
							</div><br/>	
							<div className="forinput">
								<label><h2>Assignment Approval Manager:</h2></label>								
								<input type="text" id="manager" name="manager" value = {this.state.AssignmentUpdate.filter(item=>item[9] == this.state.employeeUpdate).map(item=>item[10] + ' ' + item[11])} style={{width:'160px'}} readOnly/>									
							</div><br/>													
							<h1>{this.state.Resp}</h1><br/><br/>							
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupEmployees;