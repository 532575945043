import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class profileForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {tts:[],
			firstname:'', lastname:'', SIN1:'', SIN2:'', SIN3:'', address:'', birth1:'', birth2:'', birth3:'', postcode1:'',
			postcode2:'', basicamount:'', basicamount1:'', caregive:'', ageamount:'', pensionamount:'', tution:'', disabilityamount:'', spouseamount:'',
			dependamount:'', caregivedepend:'', caregivedepend18:'', transferredAmount:'', transferredfromdepend:'',TotalA:'',
			Pro:[],Banks:[],formtype:'',year:2020,netincome:0,statename:'',viewcomments:'', td1year:'',tdendyear:'',
			currentProfile:[],EID:'',countries:[],countryname:'',onemoreemployer:false,lessthanclaim:false,percent90yes:false,percent90no:false,
			prescribedzoon:'',additionaldeduction:'',caregiveamt:'', agetd1amount:'',agethreshold:'',ageup:'',pensionamt:'',disaamt:'',
			amt18u:'',careupamt:'',up18lessamt:'',up18threshold:'', AMTSPOUSE:'', SPOUSETH:'', SPOUSEUP:'',HOMECARE65UP:'',INFIRMDEPEND18UP:'',
			H65UPTH:'', H65UPEX:'', INFIRM18UPTH:'', INFIRM18UPEX:'',EDUPART:'', EDUFULL:'',CAREREL:'',CARETH:'',CAREUP:'',AMTSENIOR:'',
			AMTCHILD:'',pcombined:'',tutioncomment:'', TEXTFULL:'', TEXTPART:'', CEA:'', TLINES:'', BPA:'', CAREGIVE18D:'', AGE:'', 
			PENSION:'', TUTIONCOMM:'', DISABILITY:'', SPOUSE:'', DEPEND:'',	CAREGIVEDEPEND:'', CAREGIVE18UP:'', TRANSSPOUSE:'', 
			TRANSDEPEND:'', TOTALCLAIM:'', SUPP1:'', SUPP2:'', SUPP3:'',FDLINE:'',nsexceed:'',additionalcredit:'',nssumm:'',AGESUP:'',
			amtsupp1:'',amtsupp2:'',amtsupp3:'',basicamountNS:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);			
		this.handleSubmit=this.handleSubmit.bind(this)	
		this.handleChecked=this.handleChecked.bind(this)
		this.handleStateChange=this.handleStateChange.bind(this)
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {						
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});		

		axios.post(process.env.REACT_APP_API_ENV+'api/Formwording.php')
			.then(result => {						
				this.setState({tts:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')
			.then(result => {		
				this.setState({Banks:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/currentProfile.php', para)
			.then(result => {										
				this.setState({currentProfile:result.data})			
				this.setState({firstname:result.data[0]})			
				this.setState({lastname:result.data[1]})							
				this.setState({address:result.data[4]})			
				this.setState({city:result.data[5]})			
				this.setState({province:result.data[6]})			
				this.setState({bankname:result.data[14]})			
				this.setState({branch:result.data[10]})			
				this.setState({checknumber:result.data[12]})		
				this.setState({EID:result.data[15]})	
				if(result.data[2]){
					this.setState({SIN1:(result.data[2]).substring(0,3)})		
					this.setState({SIN2:(result.data[2]).substring(4,7)})		
					this.setState({SIN3:(result.data[2]).substring(8,12)})		
					this.setState({postcode1:(result.data[7]).substring(0,3)})		
					this.setState({postcode2:(result.data[7]).substring(4,7)})							
					this.setState({phone1:(result.data[3]).substring(0,3)})		
					this.setState({phone2:(result.data[3]).substring(4,7)})		
					this.setState({phone3:(result.data[3]).substring(8,12)})		
				}
				
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/taxyear.php')
			.then(result => {							
				this.setState({year:result.data})			
		})	
	}	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({viewcomments:''})
	}
	handleStateChange = event => {	
		this.setState({viewcomments:''})
		this.setState({td1year:''})
		this.setState({tdendyear:''})		
		this.setState({basicamount:''})
		this.setState({caregive:''})
		this.setState({ageamount:''})
		this.setState({pensionamount:''})
		this.setState({tution:''})
		this.setState({disabilityamount:''})
		this.setState({spouseamount:''})
		this.setState({dependamount:''})
		this.setState({caregivedepend:''})
		this.setState({caregivedepend18:''})
		this.setState({transferredAmount:''})
		this.setState({transferredfromdepend:''})				
		this.setState({onemoreemployer:false})
		this.setState({lessthanclaim:false})
		this.setState({percent90yes:false})
		this.setState({percent90no:false})
		this.setState({prescribedzoon:''})
		this.setState({additionaldeduction:''})
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if(event.target.value=='NU') {
			this.setState({tutioncomment:'Tuition, education, and textbook amounts (full time and part time)'})
			this.setState({pcombined:'MORES'})
		} else if(event.target.value=='PE'||event.target.value=='MB'||event.target.value=='NT'){
			this.setState({tutioncomment:'Tuition and education amounts (full time and part time)'})
			this.setState({pcombined:'MORES'})
		} else if(this.state.formtype == 'FD'||this.state.formtype == 'BC'||this.state.formtype == 'YT') {
			this.setState({pcombined:'MORES'})
			this.setState({tutioncomment:'Tuition (full time and part time)'})
		} else {
			this.setState({tutioncomment:'Tuition (full time and part time)'})
		}		
		const userObject={
				user:this.state.user,
				names:this.state.names,
				state: event.target.value
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/setupTD1Formbasicinformation.php',userObject)
			.then(result => {
				if(result.data[0] != 0) {
					this.setState({td1year:result.data[0]})
					this.setState({tdendyear:result.data[56]})
					this.setState({basicamount:result.data[2]})				
					this.setState({basicamount1:(result.data[2] == 0 || result.data[2]=='') ? result.data[2]:result.data[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({netincome:(result.data[3]== 0 || result.data[3]=='') ? result.data[3]: result.data[3].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})					
					this.setState({statename:result.data[14]})
					this.setState({caregiveamt:(result.data[15]==0 || result.data[15]=='') ? result.data[15]: result.data[15].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({agetd1amount:(result.data[16]==0 || result.data[16]=='') ? result.data[16]: result.data[16].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({agethreshold:(result.data[17]==0 || result.data[17]=='') ? result.data[17]: result.data[17].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({ageup:(result.data[18]==0 || result.data[18]=='') ? result.data[18]: result.data[18].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({pensionamt:(result.data[19]==0 || result.data[19]=='') ? result.data[19]: result.data[19].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({disaamt:(result.data[20]==0 || result.data[20]=='') ? result.data[20]: result.data[20].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({amt18u:(result.data[21]==0 || result.data[21]=='') ? result.data[21]: result.data[21].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({up18threshold:(result.data[22]==0 || result.data[22]=='') ? result.data[22]: result.data[22].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({up18lessamt:(result.data[23]==0 || result.data[23]=='') ? result.data[23]: result.data[23].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({careupamt:(result.data[24]==0 || result.data[24]=='') ? result.data[24]: result.data[24].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({AMTSPOUSE:(result.data[25]==0 || result.data[25]=='') ? result.data[25]: result.data[25].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({SPOUSETH:(result.data[26]==0 || result.data[26]=='') ? result.data[26]: result.data[26].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({SPOUSEUP:(result.data[27]==0 || result.data[27]=='') ? result.data[27]: result.data[27].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({HOMECARE65UP:(result.data[28]==0 || result.data[28]=='') ? result.data[28]: result.data[28].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({H65UPTH:(result.data[29]==0 || result.data[29]=='') ? result.data[29]: result.data[29].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
					this.setState({H65UPEX:(result.data[30]==0 || result.data[30]=='') ? result.data[30]: result.data[30].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({INFIRMDEPEND18UP:(result.data[31]==0 || result.data[31]=='') ? result.data[31]: result.data[31].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({INFIRM18UPTH:(result.data[32]==0 || result.data[32]=='') ? result.data[32]: result.data[32].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({INFIRM18UPEX:(result.data[33]==0 || result.data[33]=='') ? result.data[33]: result.data[33].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({EDUPART:(result.data[34]==0 || result.data[34]=='') ? result.data[34]: result.data[34].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({EDUFULL:(result.data[35]==0 || result.data[35]=='') ? result.data[35]: result.data[35].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({CAREREL:(result.data[36]==0 || result.data[36]=='') ? result.data[36]: result.data[36].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({CARETH:(result.data[37]==0 || result.data[37]=='') ? result.data[37]: result.data[37].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({CAREUP:(result.data[38]==0 || result.data[38]=='') ? result.data[38]: result.data[38].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({AMTSENIOR:(result.data[39]==0 || result.data[39]=='') ? result.data[39]: result.data[39].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({AMTCHILD:(result.data[40]==0 || result.data[40]=='') ? result.data[40]: result.data[40].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({TEXTFULL:(result.data[41]==0 || result.data[41]=='') ? result.data[41]: result.data[41].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({TEXTPART:(result.data[42]==0 || result.data[42]=='') ? result.data[42]: result.data[42].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({CEA:(result.data[43]==0 || result.data[43]=='') ? result.data[43]: result.data[43].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})							
					this.setState({basicamountNS:result.data[57]})							
					this.setState({TLINES:result.data[4]})							
					this.setState({BPA:result.data[5]})							
					this.setState({CAREGIVE18D:result.data[6]})							
					this.setState({AGE:result.data[7]})							
					this.setState({PENSION:result.data[8]})							
					this.setState({TUTIONCOMM:result.data[9]})							
					this.setState({DISABILITY:result.data[10]})							
					this.setState({SPOUSE:result.data[11]})							
					this.setState({DEPEND:result.data[12]})							
					this.setState({CAREGIVEDEPEND:result.data[13]})					
					this.setState({CAREGIVE18UP:result.data[44]})							
					this.setState({TRANSSPOUSE:result.data[45]})							
					this.setState({TRANSDEPEND:result.data[46]})							
					this.setState({TOTALCLAIM:result.data[47]})							
					this.setState({SUPP1:result.data[48]})							
					this.setState({SUPP2:result.data[49]})							
					this.setState({SUPP3:result.data[50]})							
					this.setState({FDLINE:result.data[51]})
					this.setState({nsexceed:(result.data[52]==0 || result.data[52]=='') ? result.data[52]: result.data[52].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({additionalcredit:(result.data[53]==0 || result.data[53]=='') ? result.data[53]: result.data[53].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})							
					this.setState({nssumm:(result.data[54]==0 || result.data[54]=='') ? result.data[54]: result.data[54].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})							
					this.setState({AGESUP:(result.data[55]==0 || result.data[55]=='') ? result.data[55]: result.data[55].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})	
				}
			})			
	}
	
	handleChecked = event => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
		  [name]: value
		});
	}

	
	
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {birth1, birth2, birth3, basicamount, caregive, ageamount, pensionamount, tution, disabilityamount, spouseamount, 
				dependamount,caregivedepend,caregivedepend18,transferredAmount,transferredfromdepend, totalamount, countryname,
				formtype,onemoreemployer,lessthanclaim,percent90yes,percent90no, prescribedzoon,additionaldeduction, amtsupp1,
				amtsupp2, amtsupp3} = this.state		
		if(!formtype) {
			alert('Please select TD1 Form')
			return false
		}						
		if(!birth1) {
			alert('Please enter your year of birth (YYYY)')
			return false
		}		
		if(!birth2) {
			alert('Please enter your month of birth')
			return false
		}		
		if(!birth3) {
			alert('Please enter your day of birth')
			return false
		}	
		if(!basicamount) {
			alert('Please enter your basic personal amount')
			return false
		} else {
			const userObject = {
				formtype:this.state.formtype,
				birth1:this.state.birth1, 
				birth2:this.state.birth2, 
				birth3:this.state.birth3, 
				countryname:this.state.countryname, 
				basicamount:this.state.basicamount, 
				caregive:this.state.caregive, 
				ageamount:this.state.ageamount, 
				pensionamount:this.state.pensionamount, 
				tution:this.state.tution, 
				disabilityamount:this.state.disabilityamount,
				spouseamount:this.state.spouseamount, 
				dependamount:this.state.dependamount, 
				caregivedepend:this.state.caregivedepend, 
				caregivedepend18:this.state.caregivedepend18, 
				transferredAmount:this.state.transferredAmount, 
				transferredfromdepend:this.state.transferredfromdepend, 				
				email:this.state.user,
				names:this.state.names,
				year:this.state.year,
				onemoreemployer:this.state.onemoreemployer,
				lessthanclaim:this.state.lessthanclaim,
				percent90yes:this.state.percent90yes,
				percent90no:this.state.percent90no,
				prescribedzoon:this.state.prescribedzoon,
				additionaldeduction:this.state.additionaldeduction,
				amtsupp1:this.state.amtsupp1,
				amtsupp2:this.state.amtsupp2,
				amtsupp3:this.state.amtsupp3				
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/td1form.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});					
				}).catch((error) => {
					console.log(error)
				});
		}
	}
	
	render() {		
		const TTAmount=parseFloat(this.state.basicamount==''? 0:this.state.basicamount)+parseFloat(this.state.caregive==''? 0:this.state.caregive)+
						parseFloat(this.state.ageamount==''? 0:this.state.ageamount)+parseFloat(this.state.pensionamount==''? 0: this.state.pensionamount)+
						parseFloat(this.state.tution==''? 0:this.state.tution)+parseFloat(this.state.disabilityamount==''? 0:this.state.disabilityamount)+
						parseFloat(this.state.spouseamount==''?0:this.state.spouseamount)+parseFloat(this.state.dependamount==''?0:this.state.dependamount)+
						parseFloat(this.state.caregivedepend==''?0:this.state.caregivedepend)+parseFloat(this.state.caregivedepend18==''?0:this.state.caregivedepend18)+
						parseFloat(this.state.transferredAmount==''?0:this.state.transferredAmount)+parseFloat(this.state.transferredfromdepend==''?0:this.state.transferredfromdepend)+						
						parseFloat(this.state.amtsupp1==''?0:this.state.amtsupp1)+parseFloat(this.state.amtsupp2==''?0:this.state.amtsupp2)+parseFloat(this.state.amtsupp3==''?0:this.state.amtsupp3)						
		return (
			<div>      			
				<CommonHeader titles="TD1 Form"/>
				<form onSubmit={this.handleSubmit}>
					<label><h2>{this.state.td1year > 0 ? this.state.td1year: this.state.year} Personal Tax Credit Return - TD1 Form</h2></label>							
					<select name="formtype" id="formtype" style={{width:'150px'}} value={this.state.formtype} onChange={this.handleStateChange}>
									<option value="">--Select TD1 Form--</option>														
									<option value="FD">Federal</option>															
									{this.state.Pro.filter(item=>item[0] == this.state.province).map(item=>(<option value={item[0]}>{item[1]}</option>))}
					</select><br/><br/>		
					{(()=>{if(this.state.td1year == 0 && this.state.formtype != "") {return (
						<div style={{textAlign:'left'}}><h1>Please wait for system to set up {this.state.year} TD1 Form information first and come back later. 
								If you don't fill up the TD1 Form, the system will use {this.state.Pro.filter(item=>item[0] == this.state.formtype).map(item=>item[1])} basic personal amount for your payroll deduction</h1><br/><br/>						
					</div>)} else if(this.state.td1year != this.state.year && this.state.tdendyear == 0 && this.state.formtype != "") {return (
						<div style={{textAlign:'left'}}><h1>Below TD1 Form is for {this.state.td1year}. Please wait for system to set up {this.state.year} TD1 Form information first and come back later. 
								If you don't fill up the TD1 Form, the system will use {this.state.Pro.filter(item=>item[0] == this.state.formtype).map(item=>item[1])} basic personal amount for your payroll deduction</h1><br/><br/>
					</div>)} else if(this.state.td1year == this.state.year && this.state.tdendyear == 0 && this.state.formtype != "") {return (
						<div style={{textAlign:'left'}}><h1>Please enter your TD1 Form information here. If you don't fill up the TD1 Form, the system will use {this.state.Pro.filter(item=>item[0] == this.state.formtype).map(item=>item[1])} basic personal amount for your payroll deduction</h1><br/><br/>
					</div>)} else if(this.state.td1year != this.state.year && this.state.tdendyear > 1 && this.state.formtype != "") {return (
						<div style={{textAlign:'left'}}><h1>You have entered your {this.state.tdendyear} TD1 Form. If you have nothing to change, 
								you don't need fill up {this.state.year} TD1 Form, the system will take care of this for you</h1><br/><br/>									
					</div>)} else if(this.state.td1year == this.state.year && this.state.td1year != this.state.tdendyear && this.state.tdendyear > 1 && this.state.formtype != "") {return (
						<div style={{textAlign:'left'}}><h1>You have entered your {this.state.tdendyear} TD1 Form. If you have nothing to change, 
								you don't need fill up {this.state.year} TD1 Form, the system will take care of this for you</h1><br/><br/>												
					</div>)} else if(this.state.td1year == this.state.year && this.state.td1year == this.state.tdendyear && this.state.tdendyear > 1 && this.state.formtype != "") {return (
						<div style={{textAlign:'left'}}><h1>You have already entered your {this.state.year} TD1 Form.</h1><br/><br/>									
					</div>)}})()} 
					{(()=>{if(this.state.formtype != '' && this.state.td1year !=0) {return (<div>
						<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}><a href={"../files/TD1 Form_"+this.state.formtype+".pdf"} target="_blank">Personal Tax Credits Return -- TD1{this.state.formtype=='FD'?'':this.state.formtype} Form </a></h1>
						<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Read page bottom instruction before filling out this form. Your employer or payer will use this form to determine the amount of your {this.state.formtype=="FD" ? "":(this.state.formtype=="YT"||this.state.formtype=="NU"||this.state.formtype=="NT")?"territorial ":"provincial "} tax deductions.</h1><br/>
						<p style={{textAlign:'left'}}>Fill out this form based on the best estimate of your circumstances.
						{(this.state.formtype=="FD"||this.state.formtype=="YT") ? " If you do not fill out this form, your tax deductions will only include the basic personal amount,estimated by your employer or payer based on the income they pay you.":""} 
						</p><br/><br/>					
						
						<div className="forinput">						
							<label className="labelname"><h2><b>First Name and Initials</b>: {this.state.firstname} & {this.state.firstname.substr(0,1)}{this.state.lastname.substr(0,1)}</h2></label>						
							<label className="labelname"><h2><b>Last Name</b>: {this.state.lastname}</h2></label>						
						</div><br/>
						<div className="forinput">						
							<label className="labelname"><h2><b>Employee Number</b>:</h2></label>						
							<label className="labelname"><h2><b>Address</b>: {this.state.address}, {this.state.city}, {this.state.province}</h2></label>						
						</div><br/>
						<div className="forinput">						
							<label className="labelname"><h2><b>Post Code</b>: {this.state.postcode1} - {this.state.postcode2}</h2></label>						
							<label className="labelname"><h2><b>Social Insurance Number</b>: {this.state.SIN1}-{this.state.SIN2}-{this.state.SIN3}</h2></label>						
						</div><br/>
						<div className="forinput">
							<label className="labelname">Date of Birth (YYYY/MM/DD): </label>
							<input type="text" name ="birth1" id="birth1" style={{width:'60px'}} value={this.state.birth1} onChange={this.handleChange} maxLength="4"/>-
							<input type="text" name ="birth2" id="birth2" style={{width:'35px'}} value={this.state.birth2} onChange={this.handleChange} maxLength="2"/>-
							<input type="text" name ="birth3" id="birth3" style={{width:'35px'}} value={this.state.birth3} onChange={this.handleChange} maxLength="2"/>
						</div><br/>
						<div className="forinput">
							<label className="labelname">Country of perment residence:</label>
							<input name="countryname" style={{width:'150px'}} value={this.state.countryname} onChange={this.handleChange}/>						
						</div>(For non-residents only)<br/><br/>
						{/*Federal*/}
						{(()=>{if(this.state.formtype === 'FD') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Caregive Amount for FD and YT*/}						
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">{this.state.formtype=='FD'?'Canada':'Family'} Caregive Amount: </label>
								<input type="text" name ="caregive" id="caregive" style={{width:'150px'}} value={this.state.caregive} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Ontario*/}
						</div>)} else if(this.state.formtype === 'ON') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>								
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							
							<div className="forinput">
								<label className="labelname">Ontario Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>							
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Yukon*/}
						</div>)} else if(this.state.formtype === 'YT') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Caregive Amount for FD and YT*/}						
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">{this.state.formtype=='FD'?'Canada':'Family'} Caregive Amount: </label>
								<input type="text" name ="caregive" id="caregive" style={{width:'150px'}} value={this.state.caregive} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==5).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Alberta*/}
						</div>)} else if(this.state.formtype === 'AB') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*BC*/}
						</div>)} else if(this.state.formtype === 'BC') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>																				
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>																				
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Manitoba*/}
						</div>)} else if(this.state.formtype === 'MB') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>		
							{/*Family Tax Benefit*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Family Tax Benefit: </label>
								<input type="text" name ="caregive" id="caregive" style={{width:'150px'}} value={this.state.caregive} onChange={this.handleChange}/>
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*New Brunswidk*/}
						</div>)} else if(this.state.formtype === 'NB') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>							
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>								
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>							
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Newfoundland and Labrador */}
						</div>)} else if(this.state.formtype === 'NL') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Nova Scotia*/}
						</div>)} else if(this.state.formtype === 'NS') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Age amount supplement*/}	
							<p style={{textAlign:'left'}}><b>{'2.1 '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age amount supplement: </label>
								<input type="text" name ="amtsupp1" id="amtsupp1" style={{width:'150px'}} value={this.state.amtsupp1} onChange={this.handleChange}/>
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/* Spouse or common-law partner supplement*/}
							<p style={{textAlign:'left'}}><b>{'6.1 '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==5).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==6).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==7).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Spouse or common-law partner supplement: </label>
								<input type="text" name ="amtsupp2" id="amtsupp2" style={{width:'150px'}} value={this.state.amtsupp2} onChange={this.handleChange} />
							</div><br/>
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*Amount for an eligible dependant supplement*/}
							<p style={{textAlign:'left'}}><b>{'7.1 '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==5).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==6).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==7).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==8).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==9).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="amtsupp3" id="amtsupp3" style={{width:'150px'}} value={this.state.amtsupp3} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Northwest Territories*/}
						</div>)} else if(this.state.formtype === 'NT') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>								
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/* Nunavut*/}
						</div>)} else if(this.state.formtype === 'NU') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>								
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Prince Edward Island*/}
						</div>)} else if(this.state.formtype === 'PE') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname">Tution: </label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}/>
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>
							{/*Saskatchewan*/}
						</div>)} else if(this.state.formtype === 'SK') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Age Amount: </label>
								<input name="ageamount" style={{width:'150px'}} value={this.state.ageamount} onChange={this.handleChange}/>							
							</div><br/>
							{/*Senior Supplementary amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Senior Supplementary amount: </label>
								<input type="text" name ="amtsupp1" id="amtsupp1" style={{width:'150px'}} value={this.state.amtsupp1} onChange={this.handleChange}/>
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Pension Income Amount: </label>
								<input type="text" name ="pensionamount" id="postcode1" style={{width:'150px'}} value={this.state.pensionamount} onChange={this.handleChange} />						
							</div><br/>															
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Disability Amount: </label>						
								<input type="text" name ="disabilityamount" id="disabilityamount" style={{width:'150px'}} value={this.state.disabilityamount} onChange={this.handleChange}/>
							</div><br/>								
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Spouse/Common Law Partner Amount: </label>
								<input name="spouseamount" style={{width:'150px'}} value={this.state.spouseamount} onChange={this.handleChange}/>							
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Amount for Dependant: </label>
								<input type="text" name ="dependamount" id="dependamount" style={{width:'150px'}} value={this.state.dependamount} onChange={this.handleChange} />
							</div><br/>
							{/*Child amount*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Child Amount:</label>
								<input type="text" name ="tution" id="tution" style={{width:'150px'}} value={this.state.tution} onChange={this.handleChange}  />
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount:</label>
								<input type="text" name ="caregivedepend" id="caregivedepend" style={{width:'150px'}} value={this.state.caregivedepend} onChange={this.handleChange}  />
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname">Caregiver Amount for Dependant Age 18+:</label>
								<input type="text" name ="caregivedepend18" id="caregivedepend18" style={{width:'150px'}} value={this.state.caregivedepend18} onChange={this.handleChange} />
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Spouse/Common-law Partner: </label>
								<input type="text" name ="transferredAmount" id="transferredAmount" style={{width:'150px'}} value={this.state.transferredAmount} onChange={this.handleChange}/>
							</div><br/>
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Amount From Dependant: </label>
								<input type="text" name ="transferredfromdepend" id="transferredfromdepend" style={{width:'150px'}} value={this.state.transferredfromdepend} onChange={this.handleChange}/>
							</div><br/>							
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname">Total Claim Amount: </label>
								<input type="text" name ="totalamount" style={{width:'150px'}} value={TTAmount} readOnly />
							</div><br/><br/>								
						</div>)}})()}
					</div>)}})()}
					{/*Instructions*/}	
					{(()=>{if(this.state.formtype != '' && this.state.td1year !=0) {return (<div>
							{/*TD1 Form Instruction*/}
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Fill Out Form TD1{this.state.formtype=='FD' ? '':this.state.formtype}</h1>							
							<p style={{textAlign:'left'}}>Fill out this form only if {this.state.formtype !='FD' ? 'you are an employee working in '+ this.state.statename + ' or a pensioner residing in '+ this.state.statename+' and ':''} any of the following apply:</p>
							<ul style={{textAlign:'left',paddingLeft:'20px'}}>
								<li>you have a new employer or payer and you will receive salary, wages, commissions, pensions, employment insurance benefits,
								or any other remuneration</li>
								<li>you want to change amounts you previously claimed (for example, the number of your eligible dependants has changed)</li>
								{(()=>{if(this.state.formtype == 'FD') {return (<div>
									<li>you want to claim the deduction for living in a prescribed zone</li>
								</div>)}})()}
								<li>you want to increase the amount of tax deducted at source</li>
							</ul>
							<p style={{textAlign:'left'}}>Sign and date it, and give it to your employer or payer.</p>
							<p style={{textAlign:'left'}}>If you do not fill out {this.state.formtype =='FD'||this.state.formtype =='YT'  ? 'this form, your tax deductions will only include the basic personal amount, estimated by your employer or payer based on the income they pay you.':'Form TD1'+this.state.formtype+', your employer or payer will deduct taxes after allowing the basic personal amount only.'}</p><br/>							
							
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>More than one employer or payer at the same time</h1>
							<p style={{textAlign:'left'}}><input type="checkbox" style={{width:'15px',height:'15px'}} name="onemoreemployer" checked={this.state.onemoreemployer} onChange={this.handleChecked}/> If you have more than one employer or payer at the same time and you have already claimed personal tax credit amounts on another 
							Form TD1{this.state.formtype=='FD' ? '':this.state.formtype} for {this.state.td1year}, you cannot claim them again. If your total income from all sources will be more than the personal tax credits you claimed on another
							Form TD1{this.state.formtype=='FD' ? '':this.state.formtype}, check this box, enter "0" on Line {this.state.TLINES} and do not fill in 
							Lines 2 to {this.state.TLINES-1}.</p><br/>
							
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Total income less than total claim amount</h1>
							<p style={{textAlign:'left'}}><input type="checkbox" style={{width:'15px',height:'15px'}} name="lessthanclaim" checked={this.state.lessthanclaim} onChange={this.handleChecked}/> Check this box if your total income for the year from all employers and payers will be less than your total claim amount on 
							Line {this.state.TLINES}. Your employer or payer will not deduct tax from your earnings.</p><br/>							
						</div>)}
					})()}
					{(()=>{if(this.state.formtype == 'FD' && this.state.td1year !=0) {return (<div>	
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Non-residents (Only fill in if you are a non-resident of Canada.)</h1>
							<p style={{textAlign:'left'}}>As a non-resident of Canada, will 90% or more of your world income be included in determining your taxable income earned in Canada in {this.state.td1year}?</p>
							<p style={{textAlign:'left',paddingLeft:'40px'}}><input type="checkbox" style={{width:'15px',height:'15px'}} name="percent90yes" checked={this.state.percent90yes} onChange={this.handleChecked}/> Yes (Fill out the previous page.)</p>
							<p style={{textAlign:'left',paddingLeft:'40px'}}><input type="checkbox" style={{width:'15px',height:'15px'}} name="percent90no" checked={this.state.percent90no} onChange={this.handleChecked}/> No (Enter "0" on Line {this.state.TLINES}, and do not fill in Lines 2 to {this.state.TLINES-1} as you are not entitled to the personal tax credits.)</p>
							<p style={{textAlign:'left'}}>If you are unsure of your residency status, call the international tax and non-resident enquiries line at 1-800-959-8281.</p><br/>
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Provincial or territorial personal tax credits return</h1>
							<p style={{textAlign:'left'}}>If your claim amount on Line {this.state.TLINES} is more than ${this.state.basicamount}, you also have to fill out a provincial or territorial TD1 form. If you are an employee, use the
							Form TD1 for your province or territory of employment. If you are a pensioner, use the Form TD1 for your province or territory of residence. Your employer or
							payer will use both this federal form and your most recent provincial or territorial Form TD1 to determine the amount of your tax deductions.
							If you are claiming the basic personal amount only, your employer or payer will deduct provincial or territorial taxes after allowing the provincial or territorial
							basic personal amount.</p>
							<p style={{textAlign:'left',paddingLeft:'30px'}}><b>Note</b>: If you are a Saskatchewan resident supporting children under 18 at any time during {this.state.td1year}, you may be able to claim the child amount on
							Form TD1SK, {this.state.td1year} Saskatchewan Personal Tax Credits Return. Therefore, you may want to fill out Form TD1SK even if you are only claiming the basic
							personal amount on this form.</p><br/>
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Deduction for living in a prescribed zone</h1>
							<p style={{textAlign:'left'}}>If you live in the Northwest Territories, Nunavut, Yukon, or another prescribed northern zone for more than six months in a row beginning or ending in {this.state.td1year},
							you can claim any of the following:</p>
							<ul style={{textAlign:'left',paddingLeft:'20px'}}>
								<li>$11.00 for each day that you live in the prescribed northern zone</li>
								<li>$22.00 for each day that you live in the prescribed northern zone if, during that time, you live in a dwelling
								that you maintain, and you are the only person living in that dwelling who is claiming this deduction</li>
							</ul>
							<p style={{textAlign:'left'}}>Employees living in a prescribed intermediate zone can claim 50% of the total of the above amounts.
							For more information, go to canada.ca/taxes-northern-residents.</p><br/>
							<div className="forinput">
								<label className="labelname">Deduction Amount for prescribed zone: </label>
								<input type="text" name ="prescribedzoon" id="prescribedzoon" style={{width:'150px'}} value={this.state.prescribedzoon} onChange={this.handleChange}/>
							</div><br/>
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Additional tax to be deducted</h1>
							<p style={{textAlign:'left'}}>You may want to have more tax deducted from each payment, especially if you receive other income, including non-employment
							income such as CPP or QPP benefits, or old age security pension. By doing this, you may not have to pay as much tax when you
							file your income tax and benefit return. To choose this option, state the amount of additional tax you want to have deducted from
							each payment. To change this deduction later, fill out a new Form TD1.</p><br/>
							<div className="forinput">
								<label className="labelname">Additional Tax Deduction: </label>
								<input type="text" name ="additionaldeduction" id="additionaldeduction" style={{width:'150px'}} value={this.state.additionaldeduction} onChange={this.handleChange}/>
							</div><br/>
						</div>)} else if(this.state.formtype != '' && this.state.td1year !=0) {return (<div>
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Additional tax to be deducted</h1>
							<p style={{textAlign:'left'}}>If you wish to have more tax deducted, fill in "Additional tax to be deducted" on the federal Form TD1.</p><br/>							
						</div>)}
					})()}
					{(()=>{if(this.state.formtype != '' && this.state.td1year !=0) {return (<div>	
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Reduction in tax deductions</h1>
							<p style={{textAlign:'left'}}>You can ask to have less tax deducted on your income tax and benefit return if you are eligible for deductions or non-refundable tax credits that are not listed
							on this form (for example, periodic contributions to a registered retirement savings plan (RRSP), child care or employment expenses, charitable donations, and
							tuition and education amounts carried forward from the previous year). To make this request, fill out Form T1213, Request to Reduce Tax Deductions at
							Source, to get a letter of authority from your tax services office. Give the letter of authority to your employer or payer. You do not need a letter of authority if
							your employer deducts RRSP contributions from your salary.</p><br/>
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Forms and publications</h1>
							<p style={{textAlign:'left'}}>To get our forms and publications, go to <b>canada.ca/cra-forms-publications</b> or call <b>1-800-959-5525</b>.</p><br/>
							<p style={{textAlign:'left'}}>Personal information (including the SIN) is collected for the purposes of the administration or enforcement of the Income Tax Act and related programs and activities including
							administering tax, benefits, audit, compliance, and collection. The information collected may be used or disclosed for purposes of other federal acts that provide for the imposition
							and collection of a tax or duty. It may also be disclosed to other federal, provincial, territorial, or foreign government institutions to the extent authorized by law. Failure to provide this
							information may result in interest payable, penalties, or other actions. Under the Privacy Act, individuals have a right of protection, access to and correction of their personal
							information, or to file a complaint with the Privacy Commissioner of Canada regarding the handling of their personal information. Refer to Personal Information Bank CRA PPU 120
							on Info Source at canada.ca/cra-info-source.</p><br/><br/>
							<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}>Certification</h1>
							<p style={{textAlign:'left'}}>I certify that the information given on this form is correct and complete</p><br/>
							<p style={{textAlign:'left'}}>It is a serious offence to make a false return. Once you click Submit button, your input name will be as your electronic signature</p>
							<p style={{textAlign:'left'}}>Signature Day: {dayjs().format('YYYY-MM-DD')}</p><br/>							
						</div>)} 
					})()}
					<h1>{this.state.Resp}</h1><br/><br/>
					{(()=>{if(this.state.formtype != '' && this.state.td1year !=0) {return (<div>
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
					</div>)}})()}
				</form>				
			</div>
		)
	}
}

export default profileForm;