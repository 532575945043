import React from 'react'
import '../main.css'
import axios from 'axios'
import SampleCheck from '../images/Canadian-Cheque.jpg'
import CommonHeader from '../clients/commons/Head'

class setupBranch extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:1,adminupdate:'', Resp:'', Nodata:'',Indust:[],Banks:[],Provs:[], SubC:[],selectedLName:'',
			LName: '', SName: props.SName, ISIC: '', CName: '', CArea:'', Cthree:'', CPhone: '', CAddress: '', CCity: '', 
			CProvince: '', postcode1:'', postcode2:'', BName: '', FINID: '', BTransit: '', CNumber: '',Country:'CAN',
			industry: '',roleid:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleSubChange=this.handleSubChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/industry.php')
			.then(result => {						
				this.setState({Indust:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});				
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});		
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')			
			.then((result) => {	
				this.setState({Banks:result.data});
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/branchupdates.php', para)
			.then(result => {												
				this.setState({SubC:result.data})								
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php', para)
			.then(result => {
				this.setState({roleid:result.data[5]})																	
		})
	}	
	
	handleSubChange = event => {
		this.setState({selectedLName:""})
		this.setState({SName:""})
		this.setState({ISIC:""})
		this.setState({CName:""})
		this.setState({CArea:""})
		this.setState({Cthree:""})
		this.setState({CPhone:""})
		this.setState({CAddress:""})
		this.setState({CCity:""})
		this.setState({CProvince:""})
		this.setState({postcode1:""})
		this.setState({postcode2:""})
		this.setState({adminupdate: event.target.value});		
		this.setState({Resp:""});
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	handleLNameChange = event => {		
		this.setState({selectedLName: event.target.value});			
		axios.post(process.env.REACT_APP_API_ENV+'api/singlebranch.php?BID='+event.target.value)
			.then(result => {			
				this.setState({Nodata:result.data})
				this.setState({singlebranch:result.data})								
				this.setState({SName:result.data[3]})								
				this.setState({CName:result.data[5]})	
				this.setState({ISIC:result.data[7]})	
				this.setState({CArea:(result.data[6]).substring(0,3)})	
				this.setState({Cthree:(result.data[6]).substring(4,7)})	
				this.setState({CPhone:(result.data[6]).substring(8,12)})	
				this.setState({CAddress:result.data[8]})	
				this.setState({CCity:result.data[9]})	
				this.setState({CProvince:result.data[10]})	
				this.setState({postcode1:(result.data[11]).substring(0,3)})	
				this.setState({postcode2:(result.data[11]).substring(4,7)})	
			})			
		this.setState({Resp:""});
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {adminupdate, selectedLName, LName, SName, ISIC, CName, CArea, Cthree, CPhone, CAddress, 
		CCity, CProvince, postcode1, postcode2, BName, BTransit, CNumber} = this.state					
		if (this.state.adminupdate === "") {
			alert('Please select manage subsidiary type, Add, Update or Delete?')
			return false		
		} 
		if (this.state.adminupdate === "1")  {
			if(!LName) {
				alert('Please enter your sub company legal name')
				return false
			}		
			if(!SName) {
				alert('Please enter your sub company short name')
				return false
			}		
			if(!ISIC) {
				alert('Please select sub company industry category')
				return false
			}
			if(!CName) {
				alert('Please enter your sub company contact person')
				return false
			}
			if(!CName) {
				alert('Please enter your sub company contact person')
				return false
			}
			if(!CArea) {
				alert('Please enter contact phone area number')
				return false
			}
			if(!Cthree) {
				alert('Please enter contact phone first three numbers ')
				return false
			}
			if(!CPhone) {
				alert('Please enter contact phone last four digits')
				return false
			}
			if(!CAddress) {
				alert('Please enter your sub company address')
				return false
			}
			if(!CCity) {
				alert('Please enter your sub company residence city')
				return false
			}
			if(!CProvince) {
				alert('Please select your sub company residence province')
				return false
			}
			if(!postcode1) {
				alert('Please enter your sub company residence post code first part')
				return false
			}
			if(!postcode2) {
				alert('Please enter your sub company residence post code last part')
				return false
			}		
			if(this.state.roleid===1) {
				if(!BName) {
					alert('Please select your sub company business bank name')
					return false
				}
				if(!BTransit) {
					alert('Please enter your sub company business bank transit number which is 5-digit number shown in reference check sample')
					return false
				}		
				if(!CNumber) {
					alert('Please enter your sub company busness bank checking account number which is 9 or 12-digit number shown in sample check')
					return false
				}
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				adminupdate:this.state.adminupdate,
				LName: this.state.LName,
				SName: this.state.SName,
				ISIC:  this.state.ISIC,
				CName: this.state.CName,
				CArea: this.state.CArea,
				Cthree: this.state.Cthree,
				CPhone: this.state.CPhone,
				CAddress: this.state.CAddress,
				CCity: this.state.CCity,
				CProvince: this.state.CProvince,
				postcode1: this.state.postcode1,
				postcode2: this.state.postcode2,
				Country: this.state.Country,				
				BName: this.state.BName,				
				BTransit: this.state.BTransit,
				CNumber: this.state.CNumber,
				roleid:this.state.roleid
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});					
				}).catch((error) => {
					console.log(error)
				});
		} else if (this.state.adminupdate === "2")  {
			if(this.state.selectedLName === "") {
				alert('Please select your sub company')
				return false
			}		
			if(!SName) {
				alert('Please enter your sub company short name')
				return false
			}		
			if(!ISIC) {
				alert('Please select sub company industry category')
				return false
			}
			if(!CName) {
				alert('Please enter your sub company contact person')
				return false
			}
			if(!CName) {
				alert('Please enter your sub company contact person')
				return false
			}
			if(!CArea) {
				alert('Please enter contact phone area number')
				return false
			}
			if(!Cthree) {
				alert('Please enter contact phone first three numbers ')
				return false
			}
			if(!CPhone) {
				alert('Please enter contact phone last four digits')
				return false
			}
			if(!CAddress) {
				alert('Please enter your sub company address')
				return false
			}
			if(!CCity) {
				alert('Please enter your sub company residence city')
				return false
			}
			if(!CProvince) {
				alert('Please select your sub company residence province')
				return false
			}
			if(!postcode1) {
				alert('Please enter your sub company residence post code first part')
				return false
			}
			if(!postcode2) {
				alert('Please enter your sub company residence post code last part')
				return false
			}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				adminupdate:this.state.adminupdate,
				LName: this.state.selectedLName,
				SName: this.state.SName,
				ISIC:  this.state.ISIC,
				CName: this.state.CName,
				CArea: this.state.CArea,
				Cthree: this.state.Cthree,
				CPhone: this.state.CPhone,
				CAddress: this.state.CAddress,
				CCity: this.state.CCity,
				CProvince: this.state.CProvince,
				postcode1: this.state.postcode1,
				postcode2: this.state.postcode2,
				Country: this.state.Country								
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {					
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});
		} else if (this.state.adminupdate === "3")  {
			if(this.state.selectedLName === "") {
				alert('Please select your sub company')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				adminupdate:this.state.adminupdate,
				LName: this.state.selectedLName				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});							
				}).catch((error) => {
					console.log(error)
				});
		}
	}
	
	render() {				
		const NodataShow = (this.state.Nodata === "Sorry, No Subsidiary was selected") ? "Sorry, No Subsidiary was selected":""
		return (		
			<div>      
				<CommonHeader titles="Branch"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Subsidiary</h2></label>							
						<select name="adminupdate" id="adminupdate" style={{width:'150px'}} value={this.state.adminupdate} onChange={this.handleSubChange}>
							<option value="">--Manage Subsidiary--</option>
							<option value="1">Add Subsidiary</option>						
							<option value="2">Update Subsidiary</option>												
							<option value="3">Delete Subsidiary</option>												
						</select><br/><br/>							
					</div>
					{/*to add new subsidiary*/}
					{(()=>{ 
						if(this.state.adminupdate === "1") {return (<div>
							<label><h2>Add Subsidiary</h2></label>
							<p>You can add as many as subsidiaries here</p><br/><br/>
							<div className="forinput">
								<label><b>Subsidiary Legal Name:</b></label>
								<input type="text" name="LName" id="LName" value = {this.state.value} onChange={this.handleChange}/>
							</div><br/>
							<div className="forinput">
								<label><b>Subsidiary Short Name:</b></label>
								<input type="text" name="SName" id="SName" value = {this.state.value} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Subsidiary Industry:</b></label>
								<select id="ISIC" name="ISIC" value = {this.state.value} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select Business Industry--</option>									
									{this.state.Indust.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
								</select>
							</div><br/>
							<div className="forinput">
								<label><b>Contact Person:</b></label>
								<input type="text" name="CName" id="CName" value = {this.state.value} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Contact Phone</b></label>
								<input type="text" style={{width:'30px'}} name="CArea" id="CArea" value = {this.state.value} onChange={this.handleChange} maxLength="3"/>
								- <input type="text" style={{width:'40px'}} name="Cthree" id="Cthree" value = {this.state.value} onChange={this.handleChange} maxLength="3"/> 
								- <input type="text" style={{width:'60px'}} name="CPhone" id="CPhone" value = {this.state.value} onChange={this.handleChange} maxLength="4"/>
							</div><br/>
							<div className="forinput">	
								<label><b>Address:</b></label>
								<input type="text" name="CAddress" id="CAddress" value = {this.state.value} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>City:</b></label>
								<input type="text" name="CCity" id="CCity" value = {this.state.value} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Province:</b></label>			
								<select id="CProvince" name="CProvince" value = {this.state.value} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select Province--</option>
									{this.state.Provs.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
								</select>
							</div><br/>
							<div className="forinput">	
								<label><b>Country:</b></label>
								<input type="text" name="CCountry" id="CCountry" value="Canada" readOnly/>
							</div><br/>
							<div className="forinput">
								<label><b>Post Code <small>(A1A-1A1)</small>:</b></label>
								<input type="text" name ="postcode1" id="postcode1" style={{width:'70px'}} value={this.state.value} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="postcode2" id="postcode2" style={{width:'70px'}} value={this.state.value} onChange={this.handleChange} maxLength="3"/>
							</div><br/>
							{(()=>{if(this.state.roleid === "1") {return (<div>
								<div className="forinput">
									<label><b>Bank Name:</b></label>			
									<select id="BName" name="BName" value = {this.state.value} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select Bank--</option>					
										{this.state.Banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}	
									</select>
								</div><br/>			
								<div className="forinput">
									<label><b>Branch Transit Number:</b></label>
									<input type="text" name="BTransit" id="BTransit"  maxLength="5" value = {this.state.value} onChange={this.handleChange} />
								</div><br/>
								<div className="forinput">
									<label><b>Checking Account Number:</b></label>
									<input type="text" name="CNumber" id="CNumber"  maxLength="12" value = {this.state.value} onChange={this.handleChange} />
								</div><br/>
								<div>
									<label><b>Please see Sample Check for your reference:</b></label><br/><br/>										
									<p><img src={SampleCheck} alt="check" /></p>	
								</div>
								<div className="forinput">
									<h2>
									<ol style={{textAlign:'left',paddingLeft:'50px'}}>
										<li>"12345"---------This is 5-digit branch transist number</li>
										<li>"999"------------This is 3-digit financial institution number</li>
										<li>"456789012"---This is chequeing account number</li>
									</ol></h2><br/><br/>
								</div><br/><br/>
							</div>)}})()}
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to update subsidiary profile information*/}
						</div>)} else if(this.state.adminupdate ==="2") {return (<div>
							<label><h2>Update Subsidiary Profile</h2></label><br/><br/>
							<div className="forinput">
								<label><b>Choose Subsidiary for the Updating:</b></label>
								<select id="LName" name="LName" value = {this.state.selectedLName} style={{width:'160px'}} onChange={this.handleLNameChange}>
									<option value="">--Select Subsidiary--</option>									
									{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
								</select>
							</div><br/>
							<div className="forinput">
								<label><b>Subsidiary Short Name:</b></label>
								<input type="text" name="SName" id="SName" value={this.state.SName} onChange={this.handleChange} />
							</div><br/>							
							<div className="forinput">
								<label><b>Subsidiary Industry:</b></label>
								<select id="ISIC" name="ISIC" value = {this.state.ISIC} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select Business Industry--</option>									
									{this.state.Indust.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
								</select>
							</div><br/>
							<div className="forinput">
								<label><b>Contact Person:</b></label>
								<input type="text" name="CName" id="CName" value = {this.state.CName} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Contact Phone</b></label>
								<input type="text" style={{width:'30px'}} name="CArea" id="CArea" value = {this.state.CArea} onChange={this.handleChange} maxLength="3"/>
								- <input type="text" style={{width:'40px'}} name="Cthree" id="Cthree" value = {this.state.Cthree} onChange={this.handleChange} maxLength="3"/> 
								- <input type="text" style={{width:'60px'}} name="CPhone" id="CPhone" value = {this.state.CPhone} onChange={this.handleChange} maxLength="4"/>
							</div><br/>
							<div className="forinput">	
								<label><b>Address:</b></label>
								<input type="text" name="CAddress" id="CAddress" value = {this.state.CAddress} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>City:</b></label>
								<input type="text" name="CCity" id="CCity" value = {this.state.CCity} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Province:</b></label>			
								<select id="CProvince" name="CProvince" value = {this.state.CProvince} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select Province--</option>
									{this.state.Provs.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
								</select>
							</div><br/>
							<div className="forinput">	
								<label><b>Country:</b></label>
								<input type="text" name="CCountry" id="CCountry" value="Canada" readOnly/>
							</div><br/>
							<div className="forinput">
								<label><b>Post Code <small>(A1A-1A1)</small>:</b></label>
								<input type="text" name ="postcode1" id="postcode1" style={{width:'70px'}} value={this.state.postcode1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="postcode2" id="postcode2" style={{width:'70px'}} value={this.state.postcode2} onChange={this.handleChange} maxLength="3"/>
							</div><br/><br/>					
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to delete selected subsidiary*/}
						</div>)} else if(this.state.adminupdate === "3"){return(<div>
							<label><h2>Choose Subsidiary to Delete</h2></label>							
							<select id="LName" name="LName" value = {this.state.selectedLName} style={{width:'160px'}} onChange={this.handleLNameChange}>
								<option value="">--Select Subsidiary--</option>									
								{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/><br/>
							<h1>{NodataShow}</h1>
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to add new banking information for selected subsidiary*/}
						</div>)}						
					})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBranch;