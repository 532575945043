import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

class setupPayRollYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:11,payrollyear:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);				
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {payrollyear} = this.state			
		if(!payrollyear) {
			alert('Please select the payroll year')
			return false
		}			
		const userObject={
			user:this.state.user,
			names:this.state.names,
			adminitem:this.state.adminitem,				
			payrollyear:this.state.payrollyear				
		}
		axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
			.then((res) => {						
				this.setState({Resp:res.data});											
			}).catch((error) => {
				console.log(error)
			});		
	}
	
	render() {						
		return (		
			<div>     
				<CommonHeader titles="Payroll Year"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Select Year</h2></label>							
						<select name="payrollyear" id="payrollyear" style={{width:'150px'}} value={this.state.payrollyear} onChange={this.handleChange}>
							<option value="">--Select Year--</option>							
							{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 300 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}																											
						</select><p>Employee Salary Payment Year</p><br/><br/>		
						<h1>{this.state.Resp}</h1><br/><br/>								
					</div>					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupPayRollYear;